<template>
  <CCard>
    <CCardBody>
      <CRow>
        <CCol sm="12" md="5">
          <div class="row">
            <ul class="nav nav-tabs" id="myTab" role="tablist">
              <li class="nav-item">
                <a class="nav-link" id="home-tab" data-toggle="tab" v-on:click="activetab=1" v-bind:class="[ activetab === 1 ? 'show active' : '' ]"
                role="tab" aria-controls="home" aria-selected="true"><i class="cid-list"></i> <i class="fa fa-user" aria-hidden="true"></i> {{$t('menu.client_list')}}</a>
              </li>
              <li class="nav-item">
                <a class="nav-link" id="profile-tab" data-toggle="tab" v-on:click="activetab=2" v-bind:class="[ activetab === 2 ? 'show active' : '' ]"
                role="tab" aria-controls="profile" aria-selected="false"><i class="cid-list"></i> <i class="fa fa-medkit"
                aria-hidden="true"></i> {{$t('menu.treatment_list')}}</a>
              </li>
            </ul>
          </div>
          <div class="tab-content" id="tab_booking_car">
            <div class="tab-pane fade" v-bind:class="[ activetab === 1 ? 'show active' : '' ]" id="tab_pane_booking_pawn" role="tabpanel" aria-labelledby="booking-tab">
              <b-form-group label-for="table-style-variant" label-cols-lg="2" class="mt-1">
                <div class="input-group">
                  <input type="text" class="form-control" :placeholder="$t('common.search_text')"
                  v-model="filter_client" maxlenght="50" @keyup.enter="apply_filter_client">
                  <div class="input-group-append">
                      <button class="btn btn-outline-secondary" @click="apply_filter_client"
                      type="button"><i class="fa fa-search" aria-hidden="true"></i> {{$t('common.search')}}</button>
                  </div>
                </div>
              </b-form-group>
              <div class="row div-card-scroll">
                <div class="col-md-4 col-xs-3 form-fixer" v-for="itm in filteredAndSortedData_client" :key="itm.id">
                    <b-card class="booking-detail"
                      @click="book_new_order(itm)"
                      :border-variant="itm.status>0?'primary':'success'">
                      <div class="d-flex justify-content-between align-items-center" slot="header">
                        <div class="bg-success">
                          <i>{{itm.name}}</i>
                        </div>
                      </div>

                      <div slot="footer">
                        <div class="btn-group pull-left" v-if="itm.status>0">
                          <button type="button" class="btn btn-outline-success btn-sm btn-block"
                          @click="client_bill_pdf(itm)" v-b-modal.viewModal>
                          <i class="fa fa-file-pdf-o"></i> {{$t('common.print')}} PDF</button>
                        </div>
                      </div>
                      <div>
                        <label class="row">{{$t('common.phone')}}: {{itm.phone}}</label>
                        <label class="row">{{$t('common.gender')}}: {{get_gender(itm.gender)}}</label>
                        <label class="row">{{$t('common.address')}}: {{itm.address}}</label>
                        <label class="row pull-right"><small class="text-muted">
                          {{$t('common.updated_time',{hour:day_diff(itm.updated_at)})}}</small></label>
                      </div>
                    </b-card>
                </div>
              </div>
              <div class="row mt-2">
                <div class="col">
                  <pager class="pull-right" :frame="8"
                        :pageCount="page_count_client"
                        :page="page_client"
                        @change="change_page_client">
                  </pager>
                </div>
              </div>
            </div>
            <div class="tab-pane fade" v-bind:class="[ activetab === 2 ? 'show active' : '' ]" id="tab_pane_order_pawn" role="tabpanel" aria-labelledby="order-tab">
              <b-form-group label-for="table-style-variant" label-cols-lg="2" class="mt-1">
                <div class="input-group">
                  <input type="text" class="form-control" :placeholder="$t('common.search_text')"
                  v-model="filter_treatment" maxlenght="50" @keyup.enter="apply_filter_treatment">
                  <div class="input-group-append">
                      <button class="btn btn-outline-secondary" @click="apply_filter_treatment"
                      type="button"><i class="fa fa-search" aria-hidden="true"></i> {{$t('common.search')}}</button>
                  </div>
                </div>
              </b-form-group>
              <b-table striped hover responsive
              :items="filteredAndSortedData_treatment" :fields="fields" :current-page="page_treatment"
              :per-page="0" @row-clicked="rowClicked">
              <template slot="top-row" slot-scope="{ fields }">
                <td v-for="field in fields" :key="field.key">
                  <input v-model="filteredAndSortedData_treatment[field.key]" 
                  v-if="field.key && field.label" :placeholder="field.label">
                </td>
              </template>
                <template slot="name" slot-scope="data">
                  <label>{{data.item.client.name}}</label>
                </template>
                <template slot="email" slot-scope="data">
                  <label>{{data.item.client.email}}</label>
                </template>
                <template slot="phone" slot-scope="data">
                  <label>{{data.item.client.phone}}</label>
                </template>
                <template slot="dob" slot-scope="data">
                  <label>{{data.item.client.dob}}</label>
                </template>
                <template slot="branch" slot-scope="data">
                  <div v-if="data.item.branch">{{data.item.branch.name}}</div>
                  <div v-else>{{data.item.company.name}}</div>
                </template>
                <template slot="total" slot-scope="data">
                  <div v-if="data.item.total>0">{{formatPrice(data.item.total)}}</div>
                  <div v-else>0</div>
                </template>
                <template slot="gender" slot-scope="data">
                  <label v-if="data.item.client">{{get_gender(data.item.client.gender)}}</label>
                  <label v-else></label>
                </template>
                <template slot="created_at" slot-scope="data">
                  <label v-if="data.item.created_at">{{VnDateTimeFormat(data.item.created_at)}}</label>
                  <label v-else></label>
                </template>
                <template slot="updated_at" slot-scope="data">
                  <label v-if="data.item.updated_at">{{VnDateTimeFormat(data.item.updated_at)}}</label>
                  <label v-else></label>
                </template>
                <template slot="status" slot-scope="data">
                  <b-badge :variant="getBadge(data.item.status)">{{getTreatmentStatus(data.item.status)}}</b-badge>
                </template>
                <template slot="in_pdf" slot-scope="data">
                  <b-button-group size="sm">
                    <b-button variant="outline-info"
                      @click="pdf(data.item.id)" v-b-modal.viewModal>
                      <i class="fa fa-file-pdf-o"></i> {{$t('common.print')}} PDF
                    </b-button>
                  </b-button-group>
                </template>

                <template slot="action" slot-scope="data">
                  <b-button-group size="sm">
                    <b-button variant="outline-danger"
                    v-if="data.item.status>-1"
                      @click="confirmDelete(data.item)" v-b-tooltip.hover.left :title="$t('common.button.delete')">
                      <i class="fa fa-trash"></i>
                    </b-button>
                    <b-button variant="outline-success" v-else
                      @click="recoverObj(data.item)" v-b-tooltip.hover.left :title="$t('common.button.recover')">
                      <i class="fa fa-recycle"></i>
                    </b-button>
                  </b-button-group>
                </template>

              </b-table>
              <template slot="footer">
                  <b-row class="pull-right">
                    <b-col>
                      <nav>
                        <pager :frame="8"
                              :pageCount="page_count_treatment"
                              :page="page_treatment"
                              @change="change_page_treatment">
                        </pager>
                      </nav>
                    </b-col>
                  </b-row>
              </template>
            </div>
          </div>
        </CCol>
        <CCol sm="12" md="7">
          <div class="row bg-info">
            <div class="col">
              <div class="h4"><i class="fa fa-user" aria-hidden="true"></i> {{$t('common.client')}}</div>
            </div>
            <div class="col">
              <strong class="pull-right h4"><i class="fa fa-money 2x"
              aria-hidden="true"></i> {{formatPrice(total_recal)}} {{$t('common.money_icon')}}</strong>
            </div>
          </div>
          <div class="row">
            <div class="col bg-secondary">
              <div class="row">
                <div class="col-4 form-fixer">
                  <b-form-group>
                    <label for="txt_client_name">{{$t('common.fullname')}}</label>
                    <div v-if="$v.client.name.$error" class="pull-right">
                        <div class="text-danger" v-if="!$v.client.name.required">{{$t('common.fullname_required')}}</div>
                        <div class="text-danger" v-else-if="!$v.client.name.maxlenght">{{$t('common.name_maxlenght')}}</div>
                    </div>
                    <vue-bootstrap-typeahead
                        v-model="client.name" id="txt_client_name" ref="txt_client_name" size="sm"
                        :placeholder="$t('common.placeholder_name')"
                        v-on:input="$v.client.name.$touch"
                        :serializer="item => item.name"
                        :data="ls_client_name"
                        @hit="selectedUser = $event"/>
                  </b-form-group>
                </div>
                <div class="col-4 form-fixer">
                  <b-form-group>
                    <label for="validation_dob">{{$t('common.dob')}}</label>
                    <div v-if="$v.client.dob.$error" class="pull-right">
                      <div class="text-danger" v-if="!$v.client.dob.required">{{$t('common.dob_required')}}</div>
                    </div>
                    <date-picker v-model.trim="client.dob" :config="options1" class="date-picker-height"
                    :placeholder="$t('common.placeholder_dob')" id="validation_dob"></date-picker>
                  </b-form-group>
                </div>
                <div class="col-4 form-fixer">
                  <b-form-group>
                    <label for="ddlGender">{{$t('common.gender')}}</label>
                    <div v-if="$v.client.gender.$error" class="pull-right">
                        <div class="text-danger" v-if="!$v.client.gender.required">{{$t('common.gender_required')}}</div>
                    </div>
                    <b-form-select id="ddlGender" v-model.trim="client.gender" size="sm">
                        <option value="null" disabled>{{$t('common.select_gender')}}</option>
                        <option v-for="d in ls_gender" :value="d.id"
                            v-bind:key="d.id">{{d.name[$i18n.locale]}}</option>
                    </b-form-select>
                  </b-form-group>
                </div>
              </div>
              <div class="row">
                <div class="col-md-3 form-fixer">
                  <b-form-group>
                    <label for="txt_dentist_phone">{{$t('common.phone')}}</label>
                    <div v-if="$v.client.phone.$error" class="pull-right">
                      <div class="text-danger" v-if="!$v.client.phone.required">{{$t('common.phone_required')}}</div>
                      <div class="text-danger" v-else-if="!$v.client.phone.numeric">{{$t('common.phone_number_only')}}</div>
                      <div class="text-danger" v-else-if="!$v.client.phone.minLength">{{$t('common.phone_ten_number')}}</div>
                      <div class="text-danger" v-else-if="!$v.client.phone.isUnique">{{$t('common.phone_existing')}}</div>
                    </div>
                    <b-form-input type="text" id="txt_dentist_phone"
                    v-model.trim="client.phone" maxlength="20"
                    v-on:input="$v.client.phone.$touch" size="sm"
                    :placeholder="$t('common.placeholder_phone')"></b-form-input>
                  </b-form-group>
                </div>
                <div class="col-md-3 form-fixer">
                  <b-form-group>
                    <label for="txtEmail">{{$t('common.email')}}</label>
                    <div v-if="$v.client.email.$error" class="pull-right">
                      <div class="text-danger" v-if="!$v.client.email.required">{{$t('common.email_invalid')}}</div>
                    </div>
                    <b-form-input type="email" id="txtEmail"
                    v-model.trim="client.email" maxlength="50" size="sm"
                    v-on:input="$v.client.email.$touch"
                    :placeholder="$t('common.placeholder_email')"></b-form-input>
                  </b-form-group>
                </div>
                <div class="col-md-6 form-fixer">
                  <b-form-group>
                    <label for="txtAddress">{{$t('common.address')}}</label>
                    <b-form-input type="text" id="txtAddress" size="sm"
                    v-model.trim="client.address" maxlength="200"
                    :placeholder="$t('common.placeholder_address')">
                    </b-form-input>
                  </b-form-group>
                </div>
              </div>
            </div>
          </div>
          <div class="row">
            <div class="col bg-info">
              <div class="h4 mt-1"><i class="fa fa-medkit" aria-hidden="true"></i> {{$t('common.list_treatment')}}</div>
            </div>
          </div>
          <div class="row div-scroll">
            <div class="col">
              <div class="row" v-for="(itm, k) in $v.treatment.treatment_detail.$each.$iter" :key="k" v-bind:class="{'bg-light': k%2==0,'':k%2!=0}">
                <div class="col">
                  <div class="row">
                    <div class="col-3">
                      <div class="mt-1 text-center"><b-badge variant="warning"><i class="fa fa-calendar" aria-hidden="true"></i> {{itm.ngaykham.$model}}</b-badge></div>
                    </div>
                    <div class="col-6">
                      <div class="h4 text-center mt-1"><b-badge variant="success" for="checkboxes-6"><i class="fa fa-history" aria-hidden="true"></i> Tiền sử bệnh</b-badge></div>
                    </div>
                    <div class="col-3 pull-right text-white mt-1">
                      <div v-if="treatment.id==null">
                        <a class="btn btn-danger mr-1 btn-sm" v-if="treatment.treatment_detail.length>1" @click="removeRow(k)"><i class="fa fa-minus" aria-hidden="true"></i></a>
                        <a class="btn btn-success btn-sm" @click="addNewRow"><i class="fa fa-plus" aria-hidden="true"></i></a>
                      </div>
                      <div v-else>
                        <a class="btn btn-danger mr-1 btn-sm" v-if="treatment.treatment_detail[k].id==null" @click="removeRow(k)"><i class="fa fa-minus" aria-hidden="true"></i></a>
                        <a class="btn btn-success btn-sm" @click="addNewRow"><i class="fa fa-plus" aria-hidden="true"></i></a>
                      </div>
                    </div>
                  </div>
                  <div class="row">
                    <b-form-checkbox-group class="ml-1 b" v-model.trim="itm.checked.$model" :id="'checkboxes_6_'+k" :name="'checkboxes_6_'+k">
                      <b-form-checkbox value="1">Tim mạch</b-form-checkbox>
                      <b-form-checkbox value="2">Phản ứng thuốc</b-form-checkbox>
                      <b-form-checkbox value="3">Có thai</b-form-checkbox>
                      <b-form-checkbox value="4">Tiểu đường</b-form-checkbox>
                      <b-form-checkbox value="5">Máu không đông</b-form-checkbox>
                      <b-form-checkbox value="6">Huyết áp</b-form-checkbox>
                    </b-form-checkbox-group>
                  </div>
                  <div class="row">
                    <div class="form-group col form-fixer">
                      <label :for="'txt_KHYC_'+k">Khám theo yêu cầu</label>
                      <textarea class="form-control" :name="'txt_KHYC_'+k" :id="'txt_KHYC_'+k" size="sm"
                      v-model.trim="itm.khamyeucau.$model" placeholder="Nhập thông tin khám" maxlength="200"></textarea>
                    </div>

                    <div class="form-group col form-fixer">
                      <label :for="'txt_chuanbenh_'+k">{{$t('common.treatment')}}</label>
                      <textarea class="form-control" :name="'txt_chuanbenh_'+k" maxlength="200" :id="'txt_chuanbenh_'+k" size="sm"
                      v-model.trim="itm.chuanbenh.$model" placeholder="Nhập chuẩn bệnh"></textarea>
                    </div>
                  </div>

                  <div class="row">
                    <div class="form-group col form-fixer">
                      <label :for="'txt_dieutri_'+k">{{$t('common.treatment_plan')}}</label>
                      <textarea class="form-control" :name="'txt_dieutri_'+k" :id="'txt_dieutri_'+k" size="sm"
                      v-model.trim="itm.dieutri.$model" :placeholder="$t('common.placeholder_treatment')" maxlength="200"></textarea>
                    </div>

                    <div class="form-group col form-fixer">
                      <label :for="'txt_kyhieurang_'+k">Ký hiệu răng</label>
                      <textarea class="form-control" :name="'txt_kyhieurang_'+k" maxlength="200" :id="'txt_kyhieurang_'+k" size="sm"
                      v-model.trim="itm.kyhieurang.$model" placeholder="Nhập ký hiệu răng"></textarea>
                    </div>
                  </div>

                  <div class="row">
                    <div class="form-group col form-fixer">
                      <label :for="'txt_fee_'+k">{{$t('common.fee')}}</label>
                      <div v-if="$v.$error" class="pull-right">
                        <div class="text-danger" v-if="!itm.fee.minVal">{{$t('common.greater_than_zero')}}</div>
                      </div>
                      <money v-model.trim="itm.fee.$model" v-bind="money" class="form-control"
                      :name="'txt_fee_'+k" :id="'txt_fee_'+k" @change.native="onFeeChange(k,itm.fee.$model)"
                      maxLength="15"></money>
                    </div>

                    <div class="form-group col form-fixer">
                      <label :for="'validation_NgayTraKQ_'+k">{{$t('common.treatment_result')}}</label>
                      <div v-if="$v.$error" class="pull-right">
                        <div class="text-danger" v-if="!itm.ngaytrakq.required">{{$t('common.issued_date_required')}}</div>
                      </div>
                      <date-picker  class="date-picker-height" :name="'validation_NgayTraKQ_'+k" :config="options"
                      :id="'validation_NgayTraKQ_'+k"
                      v-model.trim="itm.ngaytrakq.$model" :placeholder="$t('common.placeholder_issued_date')"></date-picker>
                    </div>
                    <div class="form-group col form-fixer">
                      <label :for="'txt_total_'+k">{{$t('common.total')}}</label>
                      <money v-model.trim="itm.total.$model" v-bind="money" class="form-control"
                      :name="'txt_total_'+k" :id="'txt_total_'+k"
                      maxLength="15" :disabled="true"></money>
                    </div>
                  </div>
                  <div class="row">
                    <fieldset class="scheduler-border" v-if="itm.treatment_product_detail.$model&&itm.treatment_product_detail.$model.length>0
                    &&itm.treatment_product_detail.$model.filter(x=>x.status>-1).length>0">
                      <legend class="scheduler-border h4">{{$t('common.pharmacy_list')}}</legend>
                      <div class="row" v-for="(product, c) in itm.treatment_product_detail.$each.$iter" :key="c">
                        <div class="form-group col-3 form-fixer-list">
                          <label :for="'txt_product_'+k+c">{{$t('common.product')}}</label>
                          <div v-if="$v.$error" class="pull-right">
                            <div class="text-danger" v-if="!product.product_id.required">{{$t('common.select_product')}}</div>
                          </div>
                          <b-form-select v-model.trim="product.product_id.$model"
                            :id="'txt_product_'+k+c" size="sm"
                            @change="onProductChange(c,product.product_id.$model,itm.treatment_product_detail.$model)">
                            <option value="null" disabled>{{$t('common.select_product')}}</option>
                            <option v-for="c in ls_product_ddl" :value="c.id"
                            v-bind:key="c.id">{{c.name}} {{c.supply?" - ["+c.supply.name+"]":""}}</option>
                          </b-form-select>
                        </div>
                        <div class="form-group col-3 form-fixer-list">
                          <label :for="'ddl_txt_dentist_unit_unit_'+k+c">{{$t('common.unit')}}</label>
                          <div v-if="$v.$error" class="pull-right">
                            <div class="text-danger" v-if="!product.unit_id.required">{{$t('common.select_unit')}}</div>
                          </div>
                          <b-form-select :id="'ddl_txt_dentist_unit_unit_'+k+c" 
                            @change="onUnitChange(k,product.unit_id.$model,itm.treatment_product_detail.$model[c])"
                            :disabled="product.product_id.$model==null"
                            v-model.trim="product.unit_id.$model" size="sm"
                            :placeholder="$t('common.select_unit')">
                            <option value="null" disabled>{{$t('common.select_unit')}}</option>
                            <option v-for="c in itm.treatment_product_detail.$model[c].ls_unit_ddl" :value="c.id"
                            v-bind:key="c.id">{{JSON.parse(c.name)[$i18n.locale]}}</option>
                          </b-form-select>
                        </div>
                        <div class="form-group col-2 form-fixer-list">
                          <label :for="'txt_dentist_product_quantity_'+k+c">{{$t('common.quantity')}}</label>
                          <div v-if="$v.$error&&product.product_id.$model&&product.unit_id.$model" class="pull-right">
                            <div class="text-danger" v-if="!product.quantity.required">{{$t('common.quantity_required')}}</div>
                            <div class="text-danger" v-else-if="!product.quantity.numeric">{{$t('common.quantity_number_only')}}</div>
                            <div class="text-danger" v-else-if="!product.quantity.maxValue">{{$t('common.quantity_max')}}</div>
                          </div>
                          <b-form-input type="number" @change="onProductQuantityChange(c,itm.$model)"
                            v-model.trim="product.quantity.$model" class="form-control"
                            :disabled="product.product_id.$model==null" size="sm" :min="0" :max="1000"
                            :name="'txt_dentist_product_quantity_'+k+c" :id="'txt_dentist_product_quantity_'+k+c"
                            maxLength="10" :input="product.quantity.$touch">
                          </b-form-input>
                        </div>
                        <div class="form-group col-2 form-fixer-list">
                          <label :for="'txt_dentist_product_price_'+k+c">{{$t('common.price')}}</label>
                          <money :value="calTotalPrice(product.$model)" v-bind="money" class="form-control"
                            :name="'txt_dentist_product_price_'+k+c" :id="'txt_dentist_product_price_'+k+c" @change.native="onProductPriceChange(c,itm.$model)"
                            maxLength="18" :disabled="product.product_id.$model==null"></money>
                        </div>
                        <div class="col-2 pull-right text-white form-inline" v-if="product.status.$model>-1" >
                          <div v-if="product.id.$model==null">
                            <a class="btn btn-danger mr-1 btn-sm" v-if="itm.treatment_product_detail.$model.length>1"
                              @click="removeProductRow(c,itm.$model)" ><i class="fa fa-minus" aria-hidden="true"></i></a>
                            <a class="btn btn-success btn-sm" @click="addProductRow(itm.treatment_product_detail.$model)"><i class="fa fa-plus" aria-hidden="true"></i></a>
                          </div>
                          <div v-else>
                            <a class="btn btn-danger mr-1 btn-sm"
                            @click="removeProductRow(c,itm.$model)"><i class="fa fa-minus" aria-hidden="true"></i></a>
                            <a class="btn btn-success btn-sm" @click="addProductRow(itm.treatment_product_detail.$model)"><i class="fa fa-plus" aria-hidden="true"></i></a>
                          </div>
                        </div>
                        <div class="col-2 pull-right text-white form-inline" v-else>
                          <div>
                            <a class="btn btn-success mr-1 btn-sm"
                              @click="refreshProductRow(c,itm.$model)"><i class="fa fa-refresh" aria-hidden="true"></i></a>
                          </div>
                        </div>
                      </div>
                    </fieldset>
                    <fieldset class="scheduler-border col-md-12" v-else>
                      <legend class="scheduler-border h4">{{$t('common.pharmacy_list')}}</legend>
                      <div class="row">
                        <div class="col-6 float-left">{{$t('common.no_product')}}</div>
                        <div class="col-6 float-right"> {{$t('common.add_product')}}
                          <a class="btn btn-success text-white btn-sm" @click="addProductRow(itm.treatment_product_detail.$model)">
                            <i class="fa fa-plus" aria-hidden="true"></i></a>
                        </div>
                      </div>
                    </fieldset>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class="row mt-2">
            <b-button variant="outline-success" class="mr-1" size="sm" @click.prevent="saveTreatment()">
              <span class="icon is-small">
                <i class="fa fa-check"></i> {{$t('common.button.save')}}</span>
            </b-button>
            <b-button variant="outline-info" size="sm"
            @click.prevent="clear()"><i class="fa fa-refresh"></i> {{$t('common.button.refresh')}}</b-button>
          </div>
        </CCol>
        <b-modal id="viewModal" title="In phiếu khám bênh" size="xl" header-bg-variant="info" no-body :ok-only="true">
          <div id="phieu_kham_benh_pdf" style="height:500px;"></div>
        </b-modal>
      </CRow>
    </CCardBody>
  </CCard>
</template>
<style scoped>
  label{
    margin-bottom: 0rem;
  }
  fieldset.scheduler-border {
    border: 1px groove #e4e7ea !important;
    padding: 0 1.4em 1.4em 1.4em !important;
    margin: 0 0 1.5em 0 !important;
    -webkit-box-shadow:  0px 0px 0px 0px #000;
            box-shadow:  0px 0px 0px 0px #000;
  }
  .div-scroll {
    max-height:100%;
    overflow-y: scroll;
    overflow-x: hidden;
  }

  legend.scheduler-border {
    font-size: 1.2em !important;
    font-weight: bold !important;
    text-align: left !important;
    width:auto;
    padding:0 10px;
    border-bottom:none;
  }
  div.card.booking-detail > div.card-body{
    padding-left: 20px;
    padding-top: 0px;
    padding-bottom: 0px;
  }
  div.card.booking-detail > div.card-header{
    padding: 0px !important;
    border-bottom:0px !important;
  }
  div.card.booking-detail > div.card-footer{
    padding: 0px !important;
    border-top:0px !important;
  }
</style>
<script>
import pdfobj from 'pdfobject'
import { validationMixin } from 'vuelidate'
import { required,requiredIf, minLength,maxLength,numeric,email }
from 'vuelidate/lib/validators'
import moment from 'moment'
import Pager from '../Pager'
import { mapGetters, mapActions } from 'vuex'
import '../../../node_modules/vue-toastr-2/dist/vue-toastr-2.min.css'
import {Role} from '../../helpers/role'
import VueBootstrapTypeahead from 'vue-bootstrap-typeahead'
import { cilObjectUngroup,cilList} from '@coreui/icons'

const greaterThanZero = (value) => value > 0

export default {
  name: 'Treatment',
  mixins: [validationMixin],
  components: {Pager,VueBootstrapTypeahead},
  props: {
    hover: {
      type: Boolean,
      default: true
    }
  },
  data: () => {
    return {
      cilObjectUngroup,
      cilList,
      activetab: 1,
      bill_id:4,
      processing:false,
      ls_client_name:[],
      selectedUser: null,
      ls_gender: [],
      treatment:{
        id:null,
        code:moment().valueOf().toString(),
        total:0,
        treatment_detail:[],
        branch_id:null,
        company_id:null,
        user_create_id:null,
        user_update_id:null,
        business:null
      },
      client:{
        id:null,
        logo:null,
        name:null,
        email:null,
        dob:null,
        gender:null,
        phone:null,
        address:null,
        des:null,
        cmnd:null,
        issued_date:null,
        issued_place:null,
        user_create_id:null,
        user_update_id:null,
        branch_id:null,
        company_id:null,
        business:null,
        code:moment().valueOf().toString()
      },
      money: {
        decimal: ',',
        thousands: '.',
        prefix: '$ ',
        precision: 0,
        masked: false
      },
      options: {
				format: 'DD-MM-YYYY hh:mm:ss',
				useCurrent: true,
				showClear: true,
        showClose: true
      },
      options1: {
				format: 'DD-MM-YYYY',
				useCurrent: true,
				showClear: true,
        showClose: true
			}
    }
  },
  validations: {
    treatment:{
      treatment_detail: {
        $each: {
          treatment_product_detail:{
            $each:{
              id:{},
              product_id:{
                required
              },
              unit_id:{
                required: requiredIf(function (itm) {
                  return itm.product_id!=null
                })
              },
              quantity:{
                required: requiredIf(function (itm) {
                  return itm.product_id!=null && itm.unit_id!=null
                }),
                maxValue: greaterThanZero,
                numeric
              },
              total:{},
              price:{},
              treatment_detail_id:{},
              status:{}
            }
          },
          fee:{
            minVal:greaterThanZero
          },
          checked:{},
          khamyeucau:{
            maxLength: maxLength(300)
          },
          dieutri:{maxLength: maxLength(300)},
          chuanbenh:{maxLength: maxLength(300)},
          kyhieurang:{maxLength: maxLength(300)},
          total: {
            required,
            numeric
          },
          ngaytrakq:{
            required
          },
          treatment_id:{},
          toa:{},
          ngaykham:{}
        }
      }
    },
    client: {
      dob:{
        required,
      },
      name: {
        required,
        maxLength:maxLength(40)
      },
      gender:{
        required
      },
      email:{
        email,
        maxLength:maxLength(50)
      },
      phone: {
        required,
        numeric,
        minLength: minLength(10),
        maxLength:maxLength(15),
        async isUnique(value) {
          if (value==null) return true
          if(this.client && this.client.id!=null) return true
          const res = await this.checkPhoneUnique(value)
          return Boolean(res.data)
        }
      }
    }
  },
  computed: {
    ...mapGetters('st_treatment', ['filter_treatment','ls_treatment','page_treatment','per_page_treatment','page_count_treatment']),
    ...mapGetters('st_client', ['ls_client','per_page_client','filter_client','page_count_client','page_client']),
    ...mapGetters('st_product', ['ls_product_ddl']),
    ...mapGetters('st_unit', ['ls_unit_ddl']),

    total_recal(){
      let temp=_.sumBy(this.treatment.treatment_detail.filter(x=>x.status>=0),"total")
      this.treatment.total=temp
      debugger
      return parseInt(temp)
    },
    user_login(){
      return this.$store.state.st_authentication.user
    },
    fields(){
      let fields=[]

      let user=this.$store.state.st_authentication.user
      if(user==null) return
      fields.push({key: 'in_pdf',label:this.$t('common.bill'),sortable: false})
      if(user.role==Role.Super_Admin){
        fields.push({key: 'company',label:this.$t('common.company'),sortable: true, variant:'info'})
        fields.push({key: 'business',label:this.$t('common.business'),sortable: true})
      }

      if(user.role==Role.Admin){
        fields.push({key: 'branch',label:this.$t('common.company_branch'),sortable: true, variant:'info'})
      }

      fields.push({key: 'name',label:this.$t('common.fullname'),sortable: true})
      fields.push({key: 'email',label:this.$t('common.email'),sortable: true})
      fields.push({key: 'phone',label:this.$t('common.phone'),sortable: true})
      fields.push({key: 'gender',label:this.$t('common.gender'),sortable: true})
      fields.push({key: 'dob',label:this.$t('common.dob'),sortable: true})
      fields.push({key: 'total',label:this.$t('common.total'),sortable: true})
      fields.push({key: 'created_at',label:this.$t('common.created_at'),sortable: true})
      fields.push({key: 'status',label:this.$t('common.status'),sortable: true})

      if(user.role==Role.Super_Admin||user.role==Role.Admin||user.role==Role.Branch_Admin){
        fields.push({key: 'action',label:'',sortable: false})
      }

      return fields
    },
    ls_product_ddl(){
      return this.$store.state.st_product.ls_product_ddl
    },
    ls_unit_ddl(){
      return this.$store.state.st_unit.ls_unit_ddl
    },
    filter_treatment:{
      get: function(){
        return this.$store.state.st_treatment.filter_treatment
      },
      set: function(text){
        this.$store.commit('st_treatment/set_filter',text)
      }
    },
    per_page_treatment:{
      get: function(){
        return this.$store.state.st_treatment.per_page_treatment
      },
      set: function(page){
        this.$store.commit('st_treatment/set_per_page',page)
      }
    },
    per_page_client:{
      get: function(){
        return this.$store.state.st_client.per_page_client
      },
      set: function(page){
        this.$store.commit('st_client/set_per_page',page)
      }
    },
    filter_client:{
      get: function(){
        return this.$store.state.st_client.filter_client
      },
      set: function(text){
        this.$store.commit('st_client/set_filter',text)
      }
    },
    filteredAndSortedData_treatment:{
      get: function(){
        let vm=this
        debugger
        let result = vm.$store.state.st_treatment.ls_treatment
        if (vm.filter_treatment) {
          result = result.filter(item =>vm.searchLike_treatment(item))
        }

        return result
      }
    },
    filteredAndSortedData_client:{
      get: function(){
        let vm=this
        debugger
        let result = vm.$store.state.st_client.ls_client
        if (vm.filter_client) {
          result = result.filter(item =>vm.searchLike_client(item))
        }

        return result
      }
    }
  },
  methods: {
    day_diff(str_date_time){
      let now=moment()
      let another_date=moment(str_date_time, 'YYYY-MM-DD hh:mm:ss')
      let diff=now.diff(another_date, 'minutes')
      if(diff<60){
        return diff +" "+ this.$t('common.minutes') +" "
      }else if(diff<(60*24)){
        return now.diff(another_date, 'hours') +" "+ this.$t('common.hours')+" "
      }else if(diff<(60*24*7)){
        return now.diff(another_date, 'days') +" "+ this.$t('common.days')+" "
      }else{
        return now.diff(another_date, 'weeks') +" "+ this.$t('common.weeks')+" "
      }
    },
    addNewRow() {
      this.treatment.treatment_detail.push({
        id:null,
        treatment_id:this.treatment.id,
        treatment_product_detail:[],
        checked:[],
        company_id:this.user_login.company_id,
        branch_id:this.user_login.branch_id,
        user_create_id:this.user_login.id,
        user_update_id:null,
        timmach:false,
        cothai:false,
        maukhongdong:false,
        phanungthuoc:false,
        tieuduong:false,
        huyetap:false,
        chuanbenh:null,
        dieutri:null,
        kyhieurang:null,
        total:0,
        fee:0,
        toa:false,
        status:0,
        ngaytrakq:moment().add(1,'hours').format('DD-MM-YYYY hh:mm:ss'),
        ngaykham:moment().format('DD-MM-YYYY hh:mm:ss'),
        khamyeucau:null,
        business:null
      })
    },
    async book_new_order(obj){
      debugger
      if(!obj) return
      let selected_order=this.filteredAndSortedData_treatment.find(x=>x.client_id==obj.id)
      if(obj.status>0 && selected_order){
        this.treatment=selected_order
        
        for (var i = 0; i < this.treatment.treatment_detail.length; i++) {
          let t_detail = this.treatment.treatment_detail[i]
          if(!Array.isArray(t_detail.checked)){
            t_detail.checked=JSON.parse(t_detail.checked.replace(/'/g, '"'))
          }

          for (var j = 0; j < t_detail.treatment_product_detail.length; j++) {
            let p_detail = t_detail.treatment_product_detail[j]
            let product=this.ls_product_ddl.find(x=>x.id==p_detail.product_id)
            if(product){
              var filteredArray  = this.ls_unit_ddl.filter(function(array_el){
                return product.product_units.filter(function(anotherOne_el){
                  if(anotherOne_el.unit_id == array_el.id){
                    array_el.buy_price= anotherOne_el.buy_price
                    array_el.sell_price= anotherOne_el.sell_price
                  }
                  return anotherOne_el.unit_id == array_el.id
                }).length > 0
              })

              if(filteredArray.length<=0){
                filteredArray=this.ls_product_ddl
              }

              p_detail.ls_unit_ddl=filteredArray
            }
          }
        }
      }else{
        this.clear()
      }
      
      this.$refs.txt_client_name.inputValue = obj.name
      this.client=obj
    },
    onFeeChange(k,money){
      if(money==null) return
      k=parseInt(k)
      if(this.treatment.treatment_detail.length>0){
        this.treatment.treatment_detail.forEach(element => {
          let sum_con=_.sumBy(element.treatment_product_detail.filter(x=>x.status>=0),"total")
          element.total=sum_con+parseInt(element.fee)
        })
      }
    },
    onProductPriceChange(c,item){
      if(item==null) return
      let temp=item.treatment_product_detail[c]
      temp.total=temp.quantity * temp.price
    },
    removeRow(k){
      if(k==null || k<0) return
      let temp=this.treatment.treatment_detail[k]
      if(temp.id==null){
        this.treatment.treatment_detail.splice(k, 1)
      }
    },
    onUnitChange(k,unit_id,item){
      if(unit_id==null) return
      k=parseInt(k)
      let unit=item.ls_unit_ddl.find(x=>x.id==unit_id)
      item.quantity=1
      if(unit){
        item.price=unit.sell_price
        item.total=item.quantity*unit.sell_price
      }
    },
    onProductChange(k,product_id,list){
      let product=this.ls_product_ddl.find(x=>x.id==product_id)
      if(product){
        var filteredArray  = this.ls_unit_ddl.filter(function(array_el){
          return product.product_units.filter(function(anotherOne_el){
            if(anotherOne_el.unit_id == array_el.id){
              array_el.buy_price= anotherOne_el.buy_price
              array_el.sell_price= anotherOne_el.sell_price
            }
            return anotherOne_el.unit_id == array_el.id
          }).length > 0
        })

        if(filteredArray.length<=0){
          filteredArray=this.ls_unit_ddl
        }

        list[k].ls_unit_ddl=filteredArray
      }
    },
    onProductQuantityChange(c,item){
      if (c < 0) return

      debugger
      let temp=item.treatment_product_detail[c]
      temp.total=temp.quantity * temp.price
    },
    calTotalPrice(item){
      if(item.quantity>=0)
        return item.price * item.quantity
      return 0
    },
    addProductRow(list) {
      if(!list) return
      list.push({
        id:null,
        product_id:null,
        unit_id:null,
        quantity:0,
        status:0,
        price:0,
        total:0,
        treatment_detail_id:null,
        ls_unit_ddl:[]
      })
    },
    refreshProductRow(c,item){
      if(c<0) return
      let sum=0
      item.treatment_product_detail[c].status=0
      let lst=item.treatment_product_detail.filter(x=>x.status>=0)
      for(var i=0; i<lst.length;i++){
        sum+= parseInt(lst[i]["quantity"])
      }
      item.quantity=sum
      item.total=sum * parseInt(item.price)
    },
    removeProductRow(c,item){
      if(c<0) return
      let sum=0
      let temp=item.treatment_product_detail[c]
      if(temp.id==null){
        item.treatment_product_detail.splice(c, 1)
      }else{
        item.treatment_product_detail[c].status=-1
      }

      let lst=item.treatment_product_detail.filter(x=>x.status>=0)
      for(var i=0; i<lst.length;i++){
        sum+= parseInt(lst[i]["quantity"])
      }
      item.quantity=sum
      item.total=sum * parseInt(item.price)
    },
    
    async client_bill_pdf(obj){
      debugger
      if(!obj) return
      let selected_order=this.filteredAndSortedData_treatment.find(x=>x.client_id==obj.id)
      if(obj.status>0 && selected_order){
        this.pdf(selected_order.id)
      }
    },
    async pdf(id){
      let app=this
      app.isLoading = true
      app.$store.dispatch('st_treatment/phieu_kham_benh_pdf',{treatment:id,lang:app.$i18n.locale}).then((response) => {
        if(response && response.data){
          let blob = new Blob([response.data], { type: 'application/pdf' })
          pdfobj.embed(window.URL.createObjectURL(blob), "#phieu_kham_benh_pdf")
        }

        app.isLoading = false
      })
    },
    ...mapActions('st_treatment', ['get_ls_treatment','apply_filter_treatment','change_page_treatment']),
    ...mapActions('st_unit', ['get_ls_unit_ddl']),
    ...mapActions('st_product', ['get_ls_product_ddl']),
    ...mapActions('st_client', ['get_ls_client','apply_filter_client','change_page_client']),
    clear(){
      this.treatment={id:null,client:null,user_create_id:this.user_login.id,user_update_id:null,treatment_detail:[],total:0,
      company_id:this.user_login.company_id,branch_id:this.user_login.branch_id,code:moment().valueOf().toString()}

      this.client={id:null,gender:null,logo:null,des:null,cmnd:null,address:null,issued_date:null,
        issued_place:null,dob:null,
        company_id:this.user_login.company_id,branch_id:this.user_login.branch_id,
        user_create_id:this.user_login.id,
        user_update_id:null,
        code:moment().valueOf().toString()}
      this.addNewRow()
      this.$refs.txt_client_name.inputValue = ''
      this.$refs.txt_client_name.$el.focus()
      this.$v.$reset()
    },
    async checkPhoneUnique(phone){
      let param={phone:phone,company_id:this.user_login.company_id}
      return await this.$store.dispatch(`st_client/phone_unique`,param)
    },
    async checkEmailUnique(email){
      debugger
      let param={email:email,company_id:this.user_login.company_id}
      return await this.$store.dispatch(`st_client/email_unique`,param)
    },
    async recoverObj(obj){
      let app=this
      if(obj.status>=0) return
      let co=await this.$store.dispatch('st_treatment/recover_obj',obj.id)
      if(co && co.data.ok){
        app.get_ls_treatment()
        app.get_ls_client()
        app.$toastr.success(app.$t("common.recovered_success"),app.$t("common.message"))
      }else{
        app.$toastr.error(app.$t("common.error_message"),app.$t("common.message"))
      }
    },
    confirmDelete(obj) {
      this.$bvModal.msgBoxConfirm(this.$t("common.delete_confirm"), {
        title: this.$t("common.confirm_delete"),
        size: 'sm',
        buttonSize: 'sm',
        okVariant: 'danger',
        okTitle: this.$t("common.delete"),
        cancelTitle: this.$t("common.cancel"),
        footerClass: 'p-2',
        hideHeaderClose: false,
        centered: false
      })
      .then(value => {
        if(value!=true) return
        this.deleteObj(obj)
      })
      .catch(err => {
        this.$toastr.error(this.$t("common.error_message"),this.$t("common.message"))
      })
    },
    async deleteObj(obj) {
      let app=this
      if(obj.status<0) return
      if(obj.status==1){
        app.$toastr.info(app.$t("common.item_using_message",{obj:app.$t("common.client")}),app.$t("common.message"))
        return
      }
      let co=await app.$store.dispatch('st_treatment/delete_obj',obj.id)
      if(co && co.data.ok){
        app.get_ls_treatment()
        app.get_ls_client()
        app.$toastr.success(app.$t("common.deleted_success"),app.$t("common.message"))
      }else{
        app.$toastr.error(app.$t("common.error_message"),app.$t("common.message"))
      }
    },
    _validations () {
      return new Promise(resolve => {
        if (this.$v.$error || !this.$v.$pending) {
            return resolve()
        }
        let poll = setInterval(() => {
        if (!this.$v.$pending) {
            clearInterval(poll)
            resolve()
        }
        }, 200)
      })
    },
    async isValid () {
        this.$v.$reset()
        this.$v.$touch()
        await this._validations()
        return Promise.resolve(!this.$v.$error)
    },
    async saveTreatment() {
      const isValid = await this.isValid()
      debugger
      if (!isValid) {
        return
      }
      debugger
      var app = this
      if (app.processing === true) {
        return
      }

      app.treatment.treatment_detail.forEach(function(entry) {
        if(entry.checked&&entry.checked.length>0){
          if (entry.checked.includes("1")) entry.timmach=true
          if (entry.checked.includes("2")) entry.phanungthuoc=true
          if (entry.checked.includes("3")) entry.cothai=true
          if (entry.checked.includes("4")) entry.tieuduong=true
          if (entry.checked.includes("5")) entry.maukhongdong=true
          if (entry.checked.includes("6")) entry.huyetap=true
        }else{
          entry.timmach=false
          entry.phanungthuoc=false
          entry.cothai=false
          entry.tieuduong=false
          entry.maukhongdong=false
          entry.huyetap=false
        }
      })

      if(app.treatment.id){
        debugger
        app.treatment.user_update_id=app.user_login.id
        app.client.user_update_id=app.user_login.id
        debugger
        app.$store.dispatch('st_treatment/update',{treatment:app.treatment,client:app.client,business:app.business})
        .then(function (resp) {
          app.get_ls_treatment()
          app.get_ls_client()
          app.clear()
          app.$toastr.success(app.$t('common.created_success'),app.$t("common.message"))
          app.processing = false
        })
        .catch(function (resp) {
          app.$toastr.error(app.$t("common.error_message"),app.$t("common.message"))
          app.processing = false
        })
      }else{
        debugger
        app.client.business=app.user_login.company.business
        app.client.company_id=app.user_login.company_id
        app.client.user_create_id=app.user_login.id

        app.treatment.client_id=app.client.id
        app.treatment.business=app.user_login.company.business
        app.treatment.company_id=app.user_login.company_id
        app.treatment.user_create_id=app.user_login.id
        app.$store.dispatch('st_treatment/create',{treatment:app.treatment,client:app.client,business:app.business})
        .then(function (resp) {
          app.get_ls_treatment()
          app.get_ls_client()
          app.clear()
          app.$toastr.success(app.$t('common.created_success'),app.$t("common.message"))
          app.processing = false
        })
        .catch(function (resp) {
          app.$toastr.error(app.$t("common.error_message"),app.$t("common.message"))
          app.processing = false
        })
      }
    },

    getRowCount (items) {
      return items.length
    },
    rowClicked (item) {
      if(!item) return
      debugger

      this.client=item.client
      this.$refs.txt_client_name.inputValue = item.client.name
      this.treatment=item

      for (var i = 0; i < item.treatment_detail.length; i++) {
        let t_detail = item.treatment_detail[i]
        if(!Array.isArray(t_detail.checked)){
          t_detail.checked=JSON.parse(t_detail.checked.replace(/'/g, '"'))
        }

        for (var j = 0; j < t_detail.treatment_product_detail.length; j++) {
          let p_detail = t_detail.treatment_product_detail[j]
          let product=this.ls_product_ddl.find(x=>x.id==p_detail.product_id)
          if(product){
            var filteredArray  = this.ls_unit_ddl.filter(function(array_el){
              return product.product_units.filter(function(anotherOne_el){
                if(anotherOne_el.unit_id == array_el.id){
                  array_el.buy_price= anotherOne_el.buy_price
                  array_el.sell_price= anotherOne_el.sell_price
                }
                return anotherOne_el.unit_id == array_el.id
              }).length > 0
            })

            if(filteredArray.length<=0){
              filteredArray=this.ls_product_ddl
            }

            p_detail.ls_unit_ddl=filteredArray
          }
        }
      }
    },
    searchLike_client:function(item){
      debugger
      return item.name.includes(this.filter_client)
      ||item.address&&item.address.includes(this.filter_client)
      ||item.des&&item.des.includes(this.filter_client)
      ||item.email&&item.email.includes(this.filter_client)
      ||item.phone&&item.phone.includes(this.filter_client)
      ||item.gender&&item.gender.toString().includes(this.filter_client)
    },
    
    searchLike_treatment:function(item){
      debugger
      return item.total.toString().includes(this.filter_treatment)
      ||item.client.name.includes(this.filter_treatment)
      ||item.client.address&&item.client.address.includes(this.filter_treatment)
      ||item.client.des&&item.client.des.includes(this.filter_treatment)
      ||item.client.email&&item.client.email.includes(this.filter_treatment)
      ||item.client.phone&&item.client.phone.includes(this.filter_treatment)
      ||item.client.gender&&item.client.gender.toString().includes(this.filter_treatment)
    },

    async getClientName(name) {
      if(name=='' || name==null || name==undefined) return
      let res=await this.$store.dispatch('st_order/get_ls_client_name_suggest',
      {company_id:this.user_login.company_id,name:name})
      debugger
      if(res && res.data && res.data.length>0){
        this.ls_client_name =res.data
      }else{
        this.client.name=name
      }
    }
  },
  mounted () {
    let vm=this
    vm.per_page_treatment=12
    vm.per_page_client=20
    vm.ls_gender=vm.option_gender
    vm.$refs.txt_client_name.$el.focus()
    vm.get_ls_product_ddl({company_id:vm.user_login.company_id,branch_id:vm.user_login.branch_id,business:vm.user_login.company.business,supply_id:null})
    vm.get_ls_unit_ddl({company_id:vm.user_login.company_id,business:vm.user_login.company.business,lang:vm.$i18n.locale})
    vm.addNewRow()
    vm.get_ls_treatment()
    vm.get_ls_client()
  },
  watch: {
    'client.name': _.debounce(function(name) { this.getClientName(name) }, 500),
    'selectedUser':function(newVal, oldVal){
      debugger
      if(newVal&& newVal.id>0){
        this.client.id=newVal.id
        this.client.name=newVal.name
        this.client.phone=newVal.phone
        this.client.email=newVal.email
        this.client.dob=newVal.dob
        this.client.address=newVal.address
        this.client.gender=newVal.gender
      }
    }
  }
}
</script>
